@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200&display=swap");
@font-face {
    font-family: "Ananias";
    src: url("./fonts/Ananias.ttf") format("truetype");
}

html {
    font-size: 18px !important;
}

body {
    font-family: "Outfit", sans-serif;
    font-weight: lighter;
    background-color: #f8f9fa;
    color: #212529;
}

nav {
    /* background-color: rgba(0, 0, 0, 0.619); */
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
}

nav .navbar-collapse {
    /* background-color: rgba(0, 0, 0, 0.619); */
}

.App {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
}

#brand-logo {
    height: 3.5rem;
}

#bg-filter {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #21252946;
}

#home img {
    max-width: 600px;
}

#home h2 {
    font-size: 1.2rem;
}

#subtitle {
    font-family: "Ananias", sans-serif;
}

.bg-tiz-transp {
    background-color: #21252980;
}

.fs-0 {
    font-size: calc(2rem + 1.5vw) !important;
}

.img-filter-1 {
    background: linear-gradient(0deg, #f8f9fa 2%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%, #f8f9fa 98%);
}

.img-filter-2 {
    background: linear-gradient(90deg, #f8f9fa 2%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%, #f8f9fa 98%);
}

.navbar-collapse {
    /* background-color: #212529; */
}

.navbar-collapse {
}

.navbar-nav {
    display: block;
    text-align: right;
}

.nav-link {
    padding: 0;
}

.nav-link.active {
    font-weight: bold;
}

.piece {
    box-sizing: border-box;
}

.grecaptcha-badge {
    visibility: hidden !important;
}

@keyframes show {
    from {
        opacity: 0;
        scale: 25%;
        top: 200%;
    }

    to {
        top: 0;
        opacity: 1;
        scale: 100%;
    }
}

.container,
.container-fluid,
img:not(.estol) {
    view-timeline-name: --image;
    view-timeline-axis: block;
    animation-timeline: --image;
    animation-name: show;
    animation-range: entry 2% cover 10%;
    animation-fill-mode: both;
}

.row .border-end {
    border-right: 0 !important;
}
/* // `lg` applies to medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    html {
        font-size: 17px !important;
    }

    #home img {
        width: 60% !important;
        max-width: 500px;
    }
}

/* // `md` applies to small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    html {
        font-size: 17px !important;
    }

    #home img {
        width: 60% !important;
        max-width: 450px;
    }
}

/* // `sm` applies to x-small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    html {
        font-size: 16px !important;
    }

    #home img {
        width: 60% !important;
        max-width: 400px;
    }
}
